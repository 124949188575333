'use client';
import {useSearchParams} from 'next/navigation';
import SearchParamsContext from '@src/contexts/search-params';
import withSuspense from '@src/components/withSuspense';

const SearchParamsProvider = ({children}) => {
  const searchParams = useSearchParams();
  return (
    <SearchParamsContext.Provider value={searchParams}>
      {children}
    </SearchParamsContext.Provider>
  );
};

export default withSuspense(SearchParamsProvider);
