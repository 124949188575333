'use client';

import {ReactNode} from 'react';

import {useMediaQuery} from '@mui/material';
import {AppRouterCacheProvider} from '@mui/material-nextjs/v14-appRouter';
import CssBaseline from '@mui/material/CssBaseline';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {usePathname} from 'next/navigation';
import posthog from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react';
import {SWRConfig} from 'swr';
import {useCacheProvider} from '@piotr-cz/swr-idb-cache';
import SmallScreen from '@components/small-screen';
import CssVariables from '@src/components/CssVariables';
import theme from '@src/library/theme';
import {IntercomProvider} from 'react-use-intercom';
import SearchParamsProvider from '@src/components/providers/search-params';

const isServer = typeof window === 'undefined';

if (!isServer) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    disable_session_recording: process.env.NODE_ENV === 'development',
    session_recording: {
      maskTextSelector: '.ProseMirror',
    },
    capture_pageview: false,
    autocapture: false,
    opt_in_site_apps: true,
  });
}

if (!isServer) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    disable_session_recording: process.env.NODE_ENV === 'development',
    session_recording: {
      maskTextSelector: '.ProseMirror',
    },
    capture_pageview: false,
    autocapture: false,
    opt_in_site_apps: true,
  });
}

export default function Providers({children}: {children: ReactNode}) {
  const pathname = usePathname();
  const smallDevice = useMediaQuery('only screen and (max-width: 500px)');
  const isLiveNotes = pathname.includes('live-notes');

  const cacheProvider = useCacheProvider({
    dbName: 'swr',
    storeName: 'cache',
  });

  if (smallDevice && !isLiveNotes) {
    return <SmallScreen />;
  }

  return (
    <AppRouterCacheProvider options={{key: 'css', prepend: true}}>
      <PostHogProvider client={posthog}>
        <IntercomProvider appId={process.env.INTERCOM_APP_ID!}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <CssVariables />
              <SWRConfig
                value={{
                  provider: cacheProvider,
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <SearchParamsProvider>{children}</SearchParamsProvider>
                </LocalizationProvider>
              </SWRConfig>
            </ThemeProvider>
          </StyledEngineProvider>
        </IntercomProvider>
      </PostHogProvider>
    </AppRouterCacheProvider>
  );
}
